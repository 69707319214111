import React, { memo, useEffect, useMemo, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { contentStyles, Section, Inner, SectionTitle, PageTitle } from 'components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { motion } from 'framer-motion';
import { preventWidow } from 'utils';

function shuffle(array: Ecosphere[]) {
  let currIdx = array.length;
  let randomIdx;

  while (currIdx !== 0) {
    randomIdx = Math.floor(Math.random() * currIdx);
    currIdx -= 1;

    [array[currIdx], array[randomIdx]] = [array[randomIdx], array[currIdx]];
  }

  return array;
}

function percFromPx(px: number) {
  return (px / 975) * 100;
}

const leftTop = [
  { left: `${percFromPx(10)}%`, top: `${percFromPx(150)}%` },
  { left: `${percFromPx(300)}%`, top: `${percFromPx(40)}%` },
  { left: `${percFromPx(590)}%`, top: `${percFromPx(440)}%` },
  { left: `${percFromPx(340)}%`, top: `${percFromPx(340)}%` },
  { left: `${percFromPx(600)}%`, top: `${percFromPx(160)}%` },
  { left: `${percFromPx(40)}%`, top: `${percFromPx(440)}%` },
  { left: `${percFromPx(250)}%`, top: `${percFromPx(620)}%` },
];

const StyledSphere = styled.div`
  ${tw`pt-[100%] h-0 w-full relative`}
`;

const SphereFill = styled.div`
  // mix-blend-mode: hard-light;
  transition: all 250ms;
  ${tw`rounded-full absolute inset-0 shadow-lg bg-white bg-opacity-5`}
  background-image: radial-gradient(circle at 33% 33%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.5) 120%), radial-gradient(circle at 40% 25%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 20%);

  ${StyledSphere}:hover & {
    ${tw`bg-blue`}
  }
`;

const SphereContent = styled.div`
  ${tw`absolute inset-0 text-white text-center rounded-full flex flex-col items-center justify-center`}
  ${StyledSphere}:hover & {
    // ${tw`text-black`}
  }
`;

interface Ecosphere {
  ecoid: string;
  label: string;
  clusterPrimaryColor: {
    hex: string;
  };
  clusterWebsite: string;
  icon: {
    basename: string;
    url: string;
  };
}

interface SphereProps {
  ecosphere: Ecosphere;
  index: number;
  amountOfCompanies: number;
}

interface EcospheresProps {
  ecospheres: Ecosphere[];
  companiesCountByEcoid: {
    [key: string]: {
      totalCount: number;
    };
  };
}

interface ModuleProps {
  heading: string;
  bodyTextNode: {
    childMarkdownRemark: {
      html: string;
    };
  };
  isHero: boolean;
}

const MotionSphereFill = motion(SphereFill);

function Sphere({ ecosphere, index, amountOfCompanies }: SphereProps) {
  const { ecoid, label, icon } = ecosphere;
  const { left, top } = leftTop[index];
  // const tech = label.toLowerCase();
  const isEven = index % 2 === 0;
  const x = isEven ? Math.random() * 4 : -Math.random() * 4;
  const y = isEven ? Math.random() * 4 : -Math.random() * 4;

  return (
    <motion.li
      css={tw`absolute w-[37%]`}
      style={{
        left,
        top,
        willChange: 'transform',
      }}
      variants={{
        initial: {
          scale: 0,
          opacity: 0,
          mixBlendMode: 'hard-light',
        },
        enter: {
          scale: 1,
          opacity: 1,
          mixBlendMode: 'hard-light',
          transition: {
            ease: [0.22, 1, 0.36, 1],
            duration: 1,
            when: 'beforeChildren',
          },
        },
      }}
    >
      <motion.div
        animate={{
          x: [0, x, 0],
          y: [0, y, 0],
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          repeatDelay: Math.random(),
          delay: Math.random(),
        }}
      >
        <Link to={`/${ecoid}`}>
          <StyledSphere>
            <MotionSphereFill
              animate={{
                scale: [1, 0.95, 1],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                repeatDelay: Math.random(),
                delay: Math.random(),
              }}
            />
            <SphereContent>
              <div css={tw`hidden lg:block w-full h-12 text-center mb-2 xl:mb-0 xl:h-20`}>
                <img
                  css={tw`max-w-full max-h-full inline-block`}
                  alt={icon.basename}
                  src={icon.url}
                />
              </div>
              <h3 css={tw`text-sm md:text-lg xl:text-xl font-bold`}>{label}</h3>
              <div css={tw`xl:mt-2 font-thin text-[0.65rem] md:text-sm opacity-50`}>
                {amountOfCompanies}

                <span> companies</span>
              </div>
            </SphereContent>
          </StyledSphere>
        </Link>
      </motion.div>
    </motion.li>
  );
}
const MemoizedSphere = memo(Sphere);

function Ecospheres({ ecospheres, companiesCountByEcoid }: EcospheresProps) {
  const [isMounted, setIsMounted] = useState(false);
  const shuffledEcospheres = useMemo(() => shuffle(ecospheres), [ecospheres]);

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  return (
    <div css={tw`relative`}>
      <div css={tw`pt-[100%] h-0 w-full relative`}>
        {isMounted && (
          <>
            <motion.ul
              css={tw`absolute inset-0`}
              initial="initial"
              animate="enter"
              variants={{
                enter: {
                  transition: {
                    staggerChildren: 0.05,
                    delayChildren: 0.25,
                  },
                },
              }}
            >
              {shuffledEcospheres?.map((ecosphere: Ecosphere, idx: number) => (
                <MemoizedSphere
                  key={ecosphere.ecoid}
                  index={idx}
                  ecosphere={ecosphere}
                  amountOfCompanies={companiesCountByEcoid[ecosphere.ecoid]?.totalCount || 0}
                />
              ))}
            </motion.ul>

            <motion.div
              css={tw`absolute right-[5%] bottom-0 lg:bottom-[2%] text-center`}
              animate={{
                scale: [1, 0.95, 1],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                repeatDelay: Math.random(),
                delay: Math.random(),
              }}
            >
              <Link to="/xtech">
                <div
                  css={tw`px-4 py-2 bg-white bg-opacity-5 rounded-xl hover:bg-opacity-75 hover:bg-blue`}
                >
                  <h3 css={tw`text-sm md:text-lg font-semibold`}>xTech</h3>
                  <div css={tw`font-thin text-[0.65rem] md:text-sm opacity-50 lg:px-2`}>
                    {companiesCountByEcoid.xtech?.totalCount || 0}

                    <span css={tw`hidden lg:inline`}> companies</span>
                  </div>
                </div>
              </Link>
            </motion.div>
          </>
        )}
      </div>

      <motion.div
        css={tw`pointer-events-none text-sm md:max-w-[156px] lg:(absolute top-0 right-0) xl:max-w-[190px] xl:text-[1rem] xl:leading-[1.1] py-10 lg:py-0`}
        initial="initial"
        animate="enter"
        variants={{
          initial: {
            opacity: 0,
            y: 10,
          },
          enter: {
            opacity: 0.5,
            y: 0,
            transition: {
              ease: [0.22, 1, 0.36, 1],
              duration: 1,
              delay: 1,
            },
          },
        }}
      >
        Press on the ecosystem to see the startups
      </motion.div>
    </div>
  );
}

const ecospheresQuery = graphql`
  query {
    allDatoCmsEcosphere(
      filter: { showInEcospheresTabs: { eq: true } }
      sort: { fields: position }
    ) {
      edges {
        node {
          ecoid
          label
          icon {
            basename
            url
          }
          clusterPrimaryColor {
            hex
          }
        }
      }
    }
    fintech: allAirtable(filter: { table: { eq: "FinTech" } }) {
      totalCount
    }
    healthtech: allAirtable(filter: { table: { eq: "HealthTech" } }) {
      totalCount
    }
    robotics: allAirtable(filter: { table: { eq: "Robotics" } }) {
      totalCount
    }
    createch: allAirtable(filter: { table: { eq: "CreaTech" } }) {
      totalCount
    }
    proptech: allAirtable(filter: { table: { eq: "PropTech" } }) {
      totalCount
    }
    agritech: allAirtable(filter: { table: { eq: "AgroTech" } }) {
      totalCount
    }
    edtech: allAirtable(filter: { table: { eq: "EdTech" } }) {
      totalCount
    }
    xtech: allAirtable(filter: { table: { eq: "Outsiders" } }) {
      totalCount
    }
  }
`;

function StartupDataBubbles({ heading, bodyTextNode, isHero }: ModuleProps) {
  const { allDatoCmsEcosphere, ...companiesCount } = useStaticQuery(ecospheresQuery);

  const ecospheres = useMemo(
    () => allDatoCmsEcosphere.edges.map((eco: { node: Ecosphere }) => eco.node),
    [allDatoCmsEcosphere.edges]
  );

  // const counts = useStaticQuery(startupDataQuery);
  // const shuffled = shuffle([0, 1, 2, 3, 4, 5, 6]);

  return (
    <Section css={tw`sm:py-10`} show={isHero}>
      <Inner>
        {isHero ? (
          <div css={tw`grid grid-cols-5 items-center justify-center`}>
            <div css={tw`col-span-full lg:col-span-2`}>
              <PageTitle>{preventWidow(heading)}</PageTitle>

              <div
                css={[contentStyles, tw`mt-6`]}
                dangerouslySetInnerHTML={{
                  __html: preventWidow(bodyTextNode?.childMarkdownRemark.html),
                }}
              />
            </div>

            <div
              css={tw`col-span-full lg:col-span-3 flex lg:pl-10 order-first lg:order-last lg:pt-0`}
            >
              <div css={tw`w-full relative`}>
                <Ecospheres ecospheres={ecospheres} companiesCountByEcoid={companiesCount} />
              </div>
            </div>
          </div>
        ) : (
          <div css={tw`md:grid grid-cols-2 gap-12 items-center justify-center`}>
            <div>
              <SectionTitle>{preventWidow(heading)}</SectionTitle>
              <div
                css={[contentStyles, tw`mt-4`]}
                dangerouslySetInnerHTML={{
                  __html: preventWidow(bodyTextNode?.childMarkdownRemark.html),
                }}
              />
            </div>
            <div css={tw`mb-4 relative`}>
              <Ecospheres ecospheres={ecospheres} companiesCountByEcoid={companiesCount} />
            </div>
          </div>
        )}
      </Inner>
    </Section>
  );
}

export default StartupDataBubbles;
